import { FC, useState } from 'react'
import { MutationUpdaterFn, PureQueryOptions } from '@apollo/client'
import gql from 'graphql-tag'
import { useSnackbar } from 'notistack'
import {
  ActivityScrapUpdateMutation,
  ActivityScrap_ActivityFragment,
  ActivityScrap_CurrentUserFragment,
  useActivityScrapUpdateMutation,
} from 'generated/graphql'
import SnackbarLink from '@app.components/SnackbarLink/SnackbarLink'
import { CHAT_ROOM_JOINABLE_ACTIVITY_TYPE } from '@app.feature/activityDetail/constant/activity.constant'
import { useChattingContext } from '@app.feature/chatting/chattingContext/module/ChattingContext'
import useIsWebview from '@app.modules/hooks/useIsWebview'
import LoginAlert from 'components/LoginAlert'
import { useCurrentUserContext } from 'hooks/useCurrentUser'
import useErrorSnackbar from 'hooks/useErrorSnackbar'
import useFlutterInappwebview from 'hooks/useFlutterInappwebview'

interface ChildrenProps {
  scrapActivity: () => void
  loading: boolean
  scrapCount?: number
}

interface ActivityScrapProps {
  activity?: ActivityScrap_ActivityFragment
  currentUser?: ActivityScrap_CurrentUserFragment | null
  refetchQueries?: PureQueryOptions[]
  update?: MutationUpdaterFn<ActivityScrapUpdateMutation>
  children(state: ChildrenProps): void
}

const ActivityScrap: FC<ActivityScrapProps> = (props) => {
  const { activity, children, refetchQueries, update } = props
  const isWebview = useIsWebview()
  const { enqueueSnackbar } = useSnackbar()
  const { enqueueApolloError } = useErrorSnackbar()
  const { joinChatRoom } = useChattingContext()
  const { fluterInappwebview } = useFlutterInappwebview()
  const [showLoginAlert, setShowLoginAlert] = useState(false)
  const { user } = useCurrentUserContext()
  const [activityScrapUpdate, { loading }] = useActivityScrapUpdateMutation({
    onError: enqueueApolloError,
    ...(refetchQueries && {
      refetchQueries,
      awaitRefetchQueries: true,
    }),
    update,
  })
  const scrapCount = activity?.scrapCount || 0

  const closeLoginAlert = () => {
    setShowLoginAlert(false)
  }

  const scrapActivity = () => {
    if (!activity?.id) {
      return
    }

    if (!user) {
      setShowLoginAlert(true)
      return
    }

    activityScrapUpdate({
      optimisticResponse: {
        __typename: 'Mutation',
        activityScrapUpdate: {
          __typename: 'ActivityScrapUpdatePayload',
          userScrap: {
            id: 'optimisticResponse_ID',
            __typename: 'UserScrap',
            user: {
              __typename: 'User',
              id: user.id,
              scrapCount:
                (user?.scrapCount || 0) + (activity?.isScrapped ? -1 : 1),
              scraps: [],
            },
          },
          activity: {
            ...activity,
            id: activity.id,
            isScrapped: !activity?.isScrapped,
            scrapCount:
              (activity.scrapCount || 0) + (activity?.isScrapped ? -1 : 1),
            __typename: 'Activity',
          },
          scrapped: !activity?.isScrapped,
        },
      },
      variables: {
        activityScrapUpdateInput: {
          activityID: activity?.id,
          referer: `/activity/${activity?.id}`,
        },
        includeScrapUsers: false,
      },
    })
    if (
      !activity.isScrapped &&
      activity.chatRoom?.id &&
      CHAT_ROOM_JOINABLE_ACTIVITY_TYPE.includes(activity?.activityTypeID || -1)
    ) {
      joinChatRoom(activity.chatRoom.id)
    }
    if (
      !activity.isScrapped &&
      activity.manager?.channel?.viewerHasFollowed === false
    ) {
      enqueueSnackbar('이 게시글을 등록한 기업을 팔로우했습니다.', {
        action: (
          <SnackbarLink href={`/channel/${activity.manager.channel.url}`} />
        ),
      })
    }

    // 스크랩을 모두 하나의 api에서 처리하기 때문에 스크랩 페이지에서 단건 삭제시에만 호출하도록 합니다
    if (isWebview) {
      activity.isScrapped
        ? fluterInappwebview?.callHandler('reportRemoveScrap')
        : fluterInappwebview?.callHandler('reportAddScrap')
    }
  }

  return (
    <>
      {children({ loading, scrapActivity, scrapCount })}
      <LoginAlert
        DialogProps={{ open: showLoginAlert }}
        onRequestClose={closeLoginAlert}
      />
    </>
  )
}

export default ActivityScrap

gql`
  fragment ActivityScrap_currentUser on User {
    id
    scrapCount
  }

  fragment ActivityScrap_activity on Activity {
    id
    isScrapped
    scrapCount
    activityTypeID
    manager {
      id
      channel {
        id
        url
        viewerHasFollowed
      }
    }
    chatRoom {
      id
    }
  }
`
