import { FC } from 'react'
import Link from 'next/link'
import styled from 'styled-components'

type TProps = {
  href: string
}

const SnackbarLink: FC<TProps> = ({ href }) => {
  return (
    <Link href={href} passHref>
      <StyledWrapper target="_blank" rel="noreferrer">
        보러가기
      </StyledWrapper>
    </Link>
  )
}

export default SnackbarLink

const StyledWrapper = styled.a`
  color: #01a0ff;
  text-decoration: underline;
  margin-right: 10px;

  &:hover {
    color: #01a0ff;
  }
`
