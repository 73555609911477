import { numberWithCommas } from 'utils'

type TProps = {
  number: number
  limit: number
}

export const getLimitNumText = ({ number, limit }: TProps) => {
  if (number <= limit) {
    return numberWithCommas(number)
  } else {
    return `${numberWithCommas(limit)}+`
  }
}
