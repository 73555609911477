import { FC } from 'react'
import gql from 'graphql-tag'
import styled, { css } from 'styled-components'
import { MessageItem_ChatMessageFragment } from 'generated/graphql'
import { checkMessageSenderInfo } from '@app.feature/activityDetail/module/chatRoom'
import MessageItemProfile from './components/MessageItemProfile'

gql`
  fragment MessageItem_chatMessage on ChatMessage {
    id
    content
    createdAt
    senderNickname
    viewerIsSender
    sender {
      chatUserType
      chatMentorAuthenticationStatus
      chatMentorOrganizationName
      chatMentorDutyName
      chatOrganization {
        name
      }
      profileImage {
        bgColor {
          id
          code
        }
        image {
          id
          url
        }
      }
    }
    chatRoom {
      activityTypeId
      isManagerRecentChatMessageSentInPopularChatRoom
      isMentorRecentChatMessageSentInPopularChatRoom
    }
  }
`

type TStyledWrapperProps = {
  viewerIsSender: boolean
}

interface IFMessageItemProps {
  message: MessageItem_ChatMessageFragment
}

const MessageItem: FC<IFMessageItemProps> = ({ message }) => {
  const { organizationManager, mentorUser, messageCreatedAt } =
    checkMessageSenderInfo(message)

  return (
    <StyledWrapper viewerIsSender={message.viewerIsSender}>
      {!message.viewerIsSender && (
        <MessageItemProfile
          nickname={message.senderNickname}
          organizationManager={organizationManager}
          mentorUser={mentorUser}
          profileImageBackground={message.sender?.profileImage.bgColor.code}
          profileImageSrc={message.sender?.profileImage.image.url}
          activityType={message.chatRoom?.activityTypeId}
        />
      )}
      <div className="item-container">
        <p className="message-content">{message.content}</p>
        <time
          className="created-at"
          // MDN 유효한 시간 형식 : https://developer.mozilla.org/ko/docs/Web/HTML/Element/time#%EC%9C%A0%ED%9A%A8%ED%95%9C_%EC%8B%9C%EA%B0%84_%EB%AC%B8%EC%9E%90%EC%97%B4
          dateTime={messageCreatedAt.replace(/\s*:\s*/g, ':')}
        >
          {messageCreatedAt}
        </time>
      </div>
    </StyledWrapper>
  )
}

export default MessageItem

const StyledWrapper = styled.li<TStyledWrapperProps>`
  ${({ theme, viewerIsSender }) => css`
    display: flex;
    flex-direction: column;
    gap: 5px;

    .item-container {
      display: flex;
      align-items: flex-end;
      padding-left: 30px;

      .message-content {
        max-width: 225px;
        padding: 10px 12px;
        font-size: 14px;
        line-height: 1.5;
        letter-spacing: -0.56px;
        word-break: break-all;
        border-radius: 8px;
        background-color: #f3f4f8;
      }

      .created-at {
        font-size: 12px;
        line-height: 2;
        letter-spacing: -0.48px;
        color: ${theme.color.text_2};
        margin: 0 5px 0 12px;
      }

      ${viewerIsSender &&
      css`
        flex-direction: row-reverse;

        .message-content {
          background-color: #eaf8ff;
        }
      `}
    }
  `}
`
