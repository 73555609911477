import { ActivityTypeID } from 'constants/enums'

export const formatBadgeTextPerActivityType = (activityTypeId: number) => {
  if (
    activityTypeId === ActivityTypeID.INTERN ||
    activityTypeId === ActivityTypeID.RECRUIT
  ) {
    return '인사담당자 대화중'
  } else if (
    activityTypeId === ActivityTypeID.ACTIVITY ||
    activityTypeId === ActivityTypeID.CONTEST
  ) {
    return '담당자 대화중'
  } else {
    return ''
  }
}
