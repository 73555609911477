import { FC } from 'react'
import { useRouter } from 'next/router'
import queryString from 'query-string'
import styled from 'styled-components'
import {
  CHAT_OPEN_TYPE,
  CHAT_OPEN_TYPE_QUERY_KEY,
} from '@app.feature/chatting/chattingContext/type/chattingQueryParams'
import AlertDialog, { AlertDialogProps } from 'components/AlertDialog'

type LoginAlertProps = Partial<AlertDialogProps> &
  Pick<AlertDialogProps, 'onRequestClose' | 'DialogProps'> & {
    chatRoomId?: string
  }

const LoginAlert: FC<LoginAlertProps> = (props) => {
  const router = useRouter()

  const goLogin = () => {
    const next = props.chatRoomId
      ? `${window.location.href}?${CHAT_OPEN_TYPE_QUERY_KEY}=${CHAT_OPEN_TYPE.DEFAULT}&chatRoomId=${props.chatRoomId}`
      : window.location.href

    router.push(`/login?${queryString.stringify({ next })}`)
  }

  return (
    <AlertDialog
      title={
        <StyledWrapper>
          <strong>로그인 후 이용가능한 서비스입니다.</strong>
          <br />
          <b>로그인 하시겠습니까?</b>
        </StyledWrapper>
      }
      onConfirm={goLogin}
      confirmLabel="확인"
      {...props}
    />
  )
}

export default LoginAlert

const StyledWrapper = styled.p`
  text-align: center;
  & > b,
  strong {
    font-size: 18px;
    font-weight: 500;
  }
`
